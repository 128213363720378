import React, {useEffect, useRef, useState} from 'react';
import {
    IonContent,
    IonPage,
    IonRouterLink,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonRow,
    IonCol,
    IonButton,
    IonImg,
    IonModal,
    IonText,
    IonGrid,
    IonThumbnail,
    IonHeader,
    IonCard,
    IonSearchbar,
    IonListHeader,
    IonFooter,
    IonItem,
    IonSlides,
    IonSlide,
    IonIcon
} from '@ionic/react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';


import './Wurld.css';
import authWrapper from '../../components/authWrapper';
import {firestore} from "../../firebaseConfig";
import firebase from "firebase";

import {downloadPictureContent, getPictureAddress, viewAssociation} from '../../util/avatarUtils';
import { sendJoinTestTeamEmail, sendGetComicBook, sendDevCallRequest} from '../../util/sendEmail';
import WurldMissionsList from '../Missions/WurldMissionsList';
import WurldChallengesList from '../Challenges/WurldChallengesList';
import {findOrCreateConvoWithMessageListRedirect, findOrCreateConvoWithMessageRedirect} from '../Chat/chat';
import {useHistory, useParams} from 'react-router';
import {closeOutline, expandOutline, volumeHighOutline, volumeMuteOutline} from "ionicons/icons";


interface WurldScreen {
    showModal: any;
    user: any;
    reload: boolean;
}

const Wurld: React.FC<WurldScreen> = (props) => {
    const [showTutorialModal, setShowTutorialModal] = useState(false);
    const [showWelcomeVideo, setShowWelcomeVideo] = useState(false);
    const [videoMuted, setVideoMuted] = useState(true);
    const [castList, setCastList] = useState([]);
    const [topCreators, setTopCreators] = useState([]);
    const [searchCast, setSearchCast] = useState('');
    const [currentTab, setCurrentTab]= useState('cast');
    const [showJoin, setShowJoin] = useState(false);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [userPages, setUserPages] = useState([]);

    //console.log("props!", props);
    //let {welcome} = useParams();
    //console.log("welcome", welcome);

    const {user:{pages, uid, photoURL, heroName, clientName, testTeamMember, skipTutorial, complete}} = props as any;

    const history = useHistory();

    const joinTestTeam = () => {
        sendJoinTestTeamEmail(props.user);
        setShowJoin(false);
    }

    const goToEvents = () => {
        window.location.href='/events';
    }
    
    const goToChallenges = () => {
         window.location.href='/trainingChallenges';
    }

    const goToDevelopmentChallenges = () => {
        window.location.href='/developmentChallenges';
    }

    const goToMerch = () => {
        window.location.href='/merch';
    }

    const toggleTutorialMode = (uid) => {
        let userDocRef = firestore.collection('users').doc(uid);

        let arrUnion = userDocRef.set({
            skipTutorial: true,
        }, {merge:true}).then(() =>
            console.log("add skip tutorial visited"))
            .catch((err) => console.log(err))
        setShowTutorialModal(false);
    }



    const goToWalkthrough = (uid) => {
        let userDocRef = firestore.collection('users').doc(uid);
        let arrUnion = userDocRef.set({
            skipTutorial: false,
        }, {merge:true}).then(() => {
            window.location.href='/walkthrough';
            console.log("add skip tutorial false")
        }).catch((err) => console.log(err))

    }

    const getComicBook  = () => {
        window.location.href='https://drive.google.com/file/d/10MbDQGdm3lrU1ZiKASuxSmXy9bRRsIjK/view?usp=sharing';
        sendGetComicBook(props.user);
    }

    const goToJoinTest = () => {
        window.location.href='/pages/joinTestTeam';
    }


    const scheduleCall = () => {
        let messageData1 = {
            answerContent: 'Development Call Request',
            messageType: 'incoming',
            checked: true,
            sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
            messageFrom: props.user.uid,
            messageTo: 'AaQJb3iqc1gA3S5WLY64sS92wpy2',
        };

        let messageData2 = {
            answerContent: "Thanks for reaching out for Development Call. To be eligible, make sure you have:" + "<br/><br/>" +
            "1. Uploaded a Profile Video." + "<br/>" +
            "2. You have created (Client) or completed (Hero/Villain) at least one Mission." + "<br/>" +
            "3. You have completed at least one Challenge in any category.",
            messageType: 'outgoing',
            checked: true,
            sendTime: firebase.firestore.Timestamp.fromDate(new Date()),
            messageFrom: 'AaQJb3iqc1gA3S5WLY64sS92wpy2',
            messageTo: props.user.uid,
        };

        let destination = {
            uid: 'AaQJb3iqc1gA3S5WLY64sS92wpy2',
            characterName: 'Justice F. Hire',
            role:'client',
            photoURL: 'profiles/thumbnails/1618265515397.jpeg',
        };

        findOrCreateConvoWithMessageListRedirect(props.user, destination,[messageData1, messageData2], history,  true, '');
        sendDevCallRequest(props.user);
    }

    const slideOpts = {
        effect: 'cube',
        zoom: false
    };

    useEffect(() => {

        console.log("user complete", complete);

        if (pages !== undefined ) {
            console.log("pages ", pages);
            let pagesList = pages.filter(item => item.wurld === true);
            console.log("pageslist", pagesList);
            if (pagesList && pagesList.length===1) {
                //setShowTutorialModal(false);
                setShowWelcomeVideo(false);
            }
        }
        else {
            console.log("show tutorial", showTutorialModal);
           // setShowTutorialModal(true);
            setShowWelcomeVideo(true);
            console.log("pages else show tutorial", pages);
            let userDocRef = firestore.collection('users').doc(uid);
            let pagesData = {
                wurld: true,
            }
            let arrUnion = userDocRef.update({
                pages: firebase.firestore.FieldValue.arrayUnion(pagesData)
            }).then(() =>
                console.log("add wurld to pages visited"))
                .catch((err) => console.log(err))
        }

        if (!skipTutorial){
            setShowTutorialModal(true);
        }

        let query = searchCast.toLowerCase()
        let stringBound = query.slice(0, query.length-1)
            + String.fromCharCode(query.slice(-1).charCodeAt(0) + 1)
        //console.log("search receiver", searchReceiver);

        if (query!='' && query.length >=1) {
            console.log("query 201", query);
            let searchCastRef = firestore.collection('users')
                .where('characterName_nocase','>=', query)
                .where('characterName_nocase','<', stringBound)
                .where('characterName_nocase','!=', '')
                .orderBy('characterName_nocase', 'asc')
                .orderBy('dateJoined', 'desc')

            searchCastRef.onSnapshot(snapshotSearch => {
                let castDocsList = [];

                snapshotSearch.forEach((foundDoc) => {
                        if (foundDoc.data() && foundDoc.data()['complete'] === true) {
                            castDocsList.push({...foundDoc.data(), uid: foundDoc.id})
                        }

                });

                setCastList(castDocsList);
                //setCastList(snapshot.docs.map(userDoc => ({...userDoc.data(), uid: userDoc.id })));

            });

            ///let topCreatorsRef = firestore.collectionGroup('content').where();
            let searchTopCreators = firestore.collection('users')
                .where('characterName_nocase','>=', query)
                .where('characterName_nocase','<', stringBound)
                .where('characterName_nocase','!=', '')
                .orderBy('characterName_nocase', 'asc')
                .orderBy('contentProduced', 'desc')
                .limit(9)
                .onSnapshot(snapshotTop => {

                    let creatorsList = [];

                    snapshotTop.forEach((creatorDoc) => {
                        if (creatorDoc.data() && creatorDoc.data()['complete'] === true) {
                            creatorsList.push({...creatorDoc.data(), uid: creatorDoc.id})
                        }
                    });

                    setTopCreators(creatorsList);
                });

            setCurrentTab('cast');
        }
        else {

            let castRef = firestore.collection('users').orderBy('dateJoined', 'desc')
                .onSnapshot(snapshotCast => {

                    let users = [];

                    snapshotCast.forEach((unfilteredDoc) => {
                        if (unfilteredDoc.data() && unfilteredDoc.data()['complete'] === true) {
                            console.log("unfiltered"+ unfilteredDoc.data());
                            users.push({...unfilteredDoc.data(), uid: unfilteredDoc.id})
                        }
                    });

                    setCastList(users);
                });

            let topCreatoresRef = firestore.collection('users').orderBy('contentProduced', 'desc')
                .onSnapshot(snapshotTopCreator => {

                    let creatorsList = [];

                    snapshotTopCreator.forEach((unfilteredTopDoc) => {
                        if (unfilteredTopDoc.data() && unfilteredTopDoc.data()['complete'] === true) {
                            creatorsList.push({...unfilteredTopDoc.data(), uid: unfilteredTopDoc.id})
                        }
                    });

                    setTopCreators(creatorsList);
                });

        }


    }, [searchCast]);

    function changeSegmentContent(selectedSegment) {

        let contentContainer = document.getElementById(selectedSegment+'Feed');
            contentContainer.style.display= 'block';
          let missionsFeedContainer = document.getElementById('missionsFeed');
          let challengesFeedContainer = document.getElementById('challengesFeed');
            let castFeedContainer = document.getElementById('castFeed');

       // let modalContainer = document.getElementsByClassName('menu sharePopover');
       // console.log("modal", modalContainer);
       // if (modalContainer && modalContainer[0] !== undefined) {
       //     let modalContainerPopopver = modalContainer[0];
       //     console.log("props is open ", props);
       //     modalContainerPopopver.setAttribute("isOpen", 'false');
       // }
       if (selectedSegment === 'missions'){
           castFeedContainer.style.display= 'none';
          challengesFeedContainer.style.display = 'none';
          setCurrentTab('missions');
        }
        else if (selectedSegment === 'challenges') {
           // setShowJoin(true);
           castFeedContainer.style.display= 'none';
           missionsFeedContainer.style.display= 'none';
           setCurrentTab('challenges');
        }
          else  if (selectedSegment === 'cast') {
           setShowSharePopup(false);
           missionsFeedContainer.style.display= 'none';
                challengesFeedContainer.style.display = 'none';
           setCurrentTab('cast');
       }

    }




    let unmuteVideo = () => {
        let welcomeVideoRefId = document.getElementById('welcomeVideoRef') as HTMLVideoElement;
        welcomeVideoRefId.muted = false;
        setVideoMuted(false);
        // if (welcomeVideoRefId) {
        //     setTimeout(function() {
        //
        //         welcomeVideoRefId.play();
        //     }, 5000);
        // }
    }

    let muteVideo = () => {
        let welcomeVideoRefId = document.getElementById('welcomeVideoRef') as HTMLVideoElement;
        welcomeVideoRefId.muted = true;
        setVideoMuted(true);
    }
    const watchIcon="https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2Fassets%2Ficons%2Fplay.svg?alt=media";
    const challengesIcon="https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2Fassets%2Ficons%2Fsuper.svg?alt=media";
    const chatIcon="https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2Fassets%2Ficons%2Fchat.svg?alt=media";
    const harmonyIcon="https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2Fassets%2Ficons%2Fharmony_white.svg?alt=media";

const hide=true;


    return (
        <IonPage>

            <Header menu={true}/>

            <IonContent className="ion-padding-bottom">
                <IonModal isOpen={showJoin}
                          cssClass='join-custom-class'
                          onDidDismiss={() => setShowJoin(false)}>
                    <IonHeader>
                        <IonRow style={{padding: 10}}>
                            <IonText className="appTextMain center">
                                {props.user.testTeamMember ? 'You can access this section in the next app test session.': 'To gain access to this section, join the JFH App Test Team.'}
                            </IonText>
                        </IonRow>
                    </IonHeader>
                    <IonContent className='join-custom-class'
                                scrollEvents={true}
                                onIonScrollStart={() => {
                                }}
                                onIonScroll={() => {
                                }}
                                onIonScrollEnd={() => {
                                }} scrollX scrollY>

                        {props.user.testTeamMember
                            ?<><IonRow><IonCol></IonCol></IonRow>
                                <IonRow><IonCol></IonCol></IonRow>
                                <IonRow>
                                    <IonText className="appTextMain center">Check your email for the invite! </IonText>
                                </IonRow>
                                <IonRow><IonCol></IonCol></IonRow>
                            </>
                            :<IonImg src="/assets/img/JoinTestTeam.png" style={{height:'150px', width:'300'}}></IonImg>}

                    </IonContent>
                    <IonFooter>
                        <IonRow className="ion-justify-content-center">
                            {props.user.testTeamMember === undefined  &&
                            <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                                <IonLabel className="italic bolder">
                                    Join
                                </IonLabel>
                            </IonButton>}
                        </IonRow>
                    </IonFooter>
                </IonModal>
                <IonSegment value={currentTab} onIonChange={(e) => changeSegmentContent(e.detail.value as any)} >
                    <IonSegmentButton value="cast">
                        <IonLabel className="labelText">wUrld</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="missions">
                        <IonLabel className="labelText">Missions</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="challenges">
                        <IonLabel className="labelText">Challenges</IonLabel>
                    </IonSegmentButton>

                </IonSegment>

                <IonContent id="castFeed"  scrollX scrollY>
                    <IonSearchbar value={searchCast} onIonChange={e => setSearchCast(e.detail.value!)} placeholder="Search ... " debounce={0}></IonSearchbar>

                    <IonSlides pager options={slideOpts}>
                        <IonSlide>
                            <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Faction_figure_evergreen.png?alt=media"} onClick={() => window.open("https://form.jotform.com/221640476807155", "_blank")}/>
                        </IonSlide>
                        <IonSlide>
                            <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FUAS2022%2F2022_action_scene_remake_competition_banner.png?alt=media"} onClick={goToDevelopmentChallenges}/>
                        </IonSlide>
                        <IonSlide>
                            <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fhero_meetup_evergreen_banner.png?alt=media"} onClick={() => {window.location.href='https://www.justiceforhire.com/events'}}/>
                        </IonSlide>
                        <IonSlide>
                            <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fmerch%2Fjfh_merch.png?alt=media"} onClick={goToMerch}/>
                        </IonSlide>
                        <IonSlide>
                            <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FReelwUrld_Crowdfunding.png?alt=media"} onClick={() => {window.location.href='https://wefunder.com/reelwurld'}}/>
                        </IonSlide>
                        {/*<IonSlide>*/}
                        {/*    <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fjfh_action_scene_competition.png?alt=media"} onClick={() => {window.location.href='https://www.justiceforhire.com/event-details/jfh-action-scene-competition-urban-action-showcase'}}/>*/}
                        {/*</IonSlide>*/}
                        {/*<IonSlide>*/}
                        {/*    <IonImg src={"/assets/img/meetup_xxii_banner.png"} onClick={goToEvents}/>*/}
                        {/*</IonSlide>*/}
                        {/*<IonSlide>*/}
                        {/*    <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/Challenges%2Fdevelopment%2Fcompetition_Banner_App_september_2021.png?alt=media"} onClick={goToDevelopmentChallenges}/>*/}
                        {/*</IonSlide>*/}
                        <IonSlide>
                            <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Ftest_team.png?alt=media"} onClick={goToJoinTest}/>
                        </IonSlide>
                        {/*<IonSlide>*/}
                        {/*    <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fhero_meetup_xxi_banner.png?alt=media&token=c55ab7f7-1f0c-43a1-b322-f7f3bfcd9b5e"} onClick={goToEvents}/>*/}
                        {/*</IonSlide>*/}
                        <IonSlide>
                            <IonImg src={"/assets/img/development_call_banner_blue.png"} onClick={scheduleCall}/>
                        </IonSlide>
                        <IonSlide>
                            <IonImg src={"/assets/img/free_jfh_comic_book.png"} onClick={getComicBook}/>
                        </IonSlide>
                        {/*<IonSlide>*/}
                        {/*    <IonImg src={"/assets/img/hero_meetup_XX_BANNER.png"} onClick={goToEvents}/>*/}
                        {/*</IonSlide>*/}
                        {/*<IonSlide>*/}
                        {/*    <IonImg src={"/assets/img/Martial_Arts_Challenge_Banner_App_July_2021.jpg"} onClick={goToChallenges}/>*/}
                        {/*</IonSlide>*/}
                        <IonSlide>
                            <IonImg src={"/assets/img/tai_chi_to_the_people.png"} onClick={goToEvents}/>
                        </IonSlide>
                        {/*<IonSlide>*/}
                        {/*    <IonImg src={"/assets/img/hero_meetup_XIX_banner.jpg"} onClick={goToEvents}/>*/}
                        {/*</IonSlide>*/}
                        {/*<IonSlide>*/}
                        {/*    <IonImg src={"/assets/img/Banner_App_June 2021.jpg"} onClick={goToEvents}/>*/}
                        {/*</IonSlide>*/}
                    </IonSlides>

                    {topCreators
                     &&
                        <IonListHeader>
                        <IonText className="brandText">Top Creators</IonText>
                       </IonListHeader>
                    }
                    {topCreators && topCreators.length === 0 && <IonItem>No record found </IonItem>}


                    {topCreators && topCreators.length > 0 &&
                     topCreators.map((topCreator, idx)=> {
                        return (

                            <IonCard key={idx} className="child3">
                                <IonThumbnail style={{'height':'74%', 'width':'100%'}}>
                                    <IonImg id={'topCreator'+idx+topCreator.uid} src={topCreator.photoURL ? downloadPictureContent(topCreator.photoURL,topCreator.uid, 'topCreator'+`${idx}`) : "https://www.gravatar.com/avatar?d=mm"}
                                            alt={"Image"}  onClick={() => viewAssociation(topCreator.uid, 'none', props.user.uid)}/>
                                </IonThumbnail>

                                <div>
                                    <IonGrid>
                                        <IonRow style={{paddingBottom:'2%'}}>
                                            {topCreator.characterName ? topCreator.characterName : (topCreator.heroName ? topCreator.heroName :topCreator.clientName)}
                                        </IonRow>

                                    </IonGrid>
                                </div>

                            </IonCard>
                        );
                    })}




                    <IonListHeader>
                        <IonText className="brandText">CAST</IonText>
                    </IonListHeader>

                    {castList && castList.length > 0 &&
                     castList.map((castMember, idx)=> {
                        return (
                            <IonCard key={idx} className="child3">
                                <IonThumbnail style={{'height':'74%', 'width':'100%'}}>
                                    <IonImg id={'castMember'+idx+castMember.uid} src={castMember.photoURL ? getPictureAddress(castMember.photoURL,castMember.uid, 'castMember'+`${idx}`) : "https://www.gravatar.com/avatar?d=mm"}
                                            alt={"Profile Image"}  onClick={() => viewAssociation(castMember.uid, 'none', props.user.uid)}/>
                                </IonThumbnail>

                                <div>
                                    <IonGrid>
                                        <IonRow style={{paddingBottom:'1%'}}>
                                        {castMember.characterName ? castMember.characterName : (castMember.heroName ? castMember.heroName :castMember.clientName)}
                                        </IonRow>

                                    </IonGrid>
                                </div>

                            </IonCard>
                        );
                     })}
                    {castList && castList.length === 0 && <IonItem>No record found </IonItem>}
                </IonContent>

                <IonContent id="missionsFeed" style={hide ? {display: 'none'} : {}}  scrollX scrollY>
                    {/*<MissionsFeed/>*/}
                    { currentTab === 'missions' &&
                        <WurldMissionsList loading={true} user={props.user} showButtonShare={showSharePopup}/>

                    }
                    {/*{missionList.map((missionItem, idxM) => {*/}
                    {/*    return (*/}
                    {/*        <WurldDeliveryModuleScreen key={idxM} deliveryModule={missionItem} showButtons={true} showPopup={showSharePopup} loggedInUser={props.user} tab="mission" />*/}
                    {/*    )*/}
                    {/*})}*/}

                </IonContent>
                <IonContent id="challengesFeed" style={hide ? {display: 'none'} : {}} scrollX scrollY>
                    {/*<ChallengesFeed />*/}

                    {
                        currentTab === 'challenges' &&
                        <WurldChallengesList loading={true} user={props.user} showButtonShare={showSharePopup}/>

                    }
                </IonContent>



                <IonModal isOpen={showTutorialModal} cssClass='my-custom-class'>
                    <IonHeader>
                        <IonRow className="ion-justify-content-center" style={{padding: 5}}>
                            <IonText className="appTextMain"> Welcome to JFH Wurld </IonText>
                        </IonRow>
                    </IonHeader>
                    <IonContent className="my-custom-class"
                                scrollEvents={true}
                                onIonScrollStart={() => {
                                }}
                                onIonScroll={() => {
                                }}
                                onIonScrollEnd={() => {
                                }} scrollX scrollY>

                        <div style={{
                            height: '100%',
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            padding: 20,
                        }}>
                            <IonRow className="ion-align-items-center">
                                <IonText className="appTextMain"> Here's what you can do inside JFH: </IonText>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonRow>
                                        <IonButton href="/watch" class="menuButton"
                                                   style={{'--background': 'none', height: 50}}>
                                            <IonThumbnail>
                                                <IonImg src={watchIcon}></IonImg>
                                            </IonThumbnail>
                                        </IonButton>
                                        <div style={{flex: 1, alignItems: 'center', display: 'flex'}}>
                                            <IonText className="appTextMain"> Watch the latest JFH episode </IonText>
                                        </div>
                                    </IonRow>
                                </IonCol>

                                <IonCol>
                                    <IonRow>
                                        <IonButton href="/challenges" class="menuButton"
                                                   style={{'--background': 'none', height: 50}}>
                                            <IonThumbnail>
                                                <IonImg src={challengesIcon}></IonImg>
                                            </IonThumbnail>
                                        </IonButton>
                                        <div style={{flex: 1, alignItems: 'center', display: 'flex'}}>
                                            <IonText className="appTextMain"> Accept Challenges to build your character </IonText>
                                        </div>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonRow>
                                        <IonButton href="/hire" class="menuButton" style={{'--background': 'none', height: 50}}>
                                            <IonThumbnail>
                                                <IonImg src={harmonyIcon}> </IonImg>
                                            </IonThumbnail>
                                        </IonButton>
                                        <div style={{flex: 1, alignItems: 'center', display: 'flex'}}>
                                            <IonText className="appTextMain"> Heroes take on Missions from Clients </IonText>
                                        </div>
                                    </IonRow>
                                </IonCol>
                                <IonCol>
                                    <IonRow>
                                        <IonButton href="/chat" class="menuButton" style={{'--background': 'none', height: 50}}>
                                            <IonThumbnail>
                                                <IonImg src={chatIcon}></IonImg>
                                            </IonThumbnail>
                                        </IonButton>
                                        <div style={{flex: 1, alignItems: 'center', display: 'flex'}}>
                                            <IonText className="appTextMain"> Chat with the cast </IonText>
                                        </div>
                                    </IonRow>
                                </IonCol>

                            </IonRow>

                            <IonRow className="ion-justify-content-center">
                                <IonText className="appTextTitle" color="secondary"> See tutorial <IonButton
                                    onClick={() => goToWalkthrough(uid)}> here </IonButton></IonText>
                            </IonRow>
                            <IonRow className="ion-justify-content-end">
                                <IonButton onClick={() => toggleTutorialMode(uid)} style={{'--background': 'none'}}>
                                    Skip for Now
                                </IonButton>
                            </IonRow>
                        </div>
                    </IonContent>

                </IonModal>

                <IonModal isOpen={showWelcomeVideo} cssClass='my-custom-class'>
                    <IonHeader>
                        <IonRow>
                            <IonCol>
                                <IonIcon onClick={() =>

                                {setShowWelcomeVideo(false); if (!skipTutorial) {
                                    setShowTutorialModal(true);
                                }}} icon={closeOutline} color="light"/>
                            </IonCol>
                            <IonCol size="9" className="ion-text-center">
                                <IonText className="appTextMain"> Welcome to JFH</IonText>
                            </IonCol>
                            <IonCol>
                                {videoMuted ?
                                    <IonIcon onClick={unmuteVideo} color="light" icon={volumeMuteOutline}/>
                                    :<IonIcon onClick={muteVideo} color="light" icon={volumeHighOutline}/>
                                }
                            </IonCol>
                        </IonRow>

                    </IonHeader>
                    <IonContent className="my-custom-class no-scroll"
                               >

                        <div style={{
                            height: '100%',
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            padding: 20,
                            overflowY: "hidden",
                        }}>

                            <video id='welcomeVideoRef'
                                   autoPlay
                                   playsInline
                                   src={'https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJFH_App_Welcome_Video-1080p_122021.mp4?alt=media'}
                                   poster={'https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2Fwelcome_to_the_jfh_app.png?alt=media'}
                                   muted={videoMuted}
                                   onEnded={() => {setShowWelcomeVideo(false);
                                   if (!skipTutorial) {
                                       setShowTutorialModal(true);
                                   }}}
                            />


                        </div>
                    </IonContent>

                </IonModal>
            </IonContent>
            <Footer/>
        </IonPage>

    );
};

export default authWrapper(Wurld);
