import React from 'react';
import '../Challenges/Challenges.css';

import { firestore } from '../../firebaseConfig';
import {PremiumContent} from "../../models/PremiumContent";
import PremiumContentPlayer from "./PremiumContentPlayer";
import {IonCard, IonSlide, IonSlides,  IonImg} from "@ionic/react";
import './Sliders.css';

interface IRLContentFeedState {
    irlContentList: PremiumContent[],
}


class IRLContentFeed extends React.Component<{},IRLContentFeedState>  {

    constructor(props) {
        super(props);

        this.state = {
            irlContentList: [],
        };
    }

    componentDidMount() {

        firestore.collection('premiumContent').where('contentType','==','irl').orderBy('date','asc')
            .onSnapshot(snapshot => {
                let irlContentList = [];
                snapshot.forEach(doc =>
                    irlContentList.push({ ...doc.data(), uid: doc.id }),
                );

                this.setState({
                    irlContentList,
                });
            });

    }


    // playIRLContent = (item) => {
    //     let videoEl  = document.getElementById(item.code) as HTMLVideoElement;
    //     videoEl.play().then(() => console.log("played video")).catch(() => console.log("error playing video"));
    // }

    render() {

        const { irlContentList } = this.state;

        const slideOptsIRL = {
            effect: 'cube',
            zoom: false
        };

        return (

            <IonSlides pager options={slideOptsIRL}>

                {irlContentList && irlContentList.map((contentItem, idx) => {
                    return (
                        <>
                        <IonSlide key={idx}>
                            <IonCard className='widthFullCard'>
                                <PremiumContentPlayer item={contentItem}/>
                            </IonCard>
                        </IonSlide>

                            {idx=== 1 ?
                                <>

                                    <IonSlide key="irlFeed1">
                                        <IonCard>
                                            <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2Fwatch%2Fjfh_workshop.png?alt=media"} onClick={()=> window.open("https://www.youtube.com/watch?v=4RfKUU-DO0I&feature=youtu.be","_blank")}/>
                                        </IonCard>
                                    </IonSlide>

                                    <IonSlide key="irlFeed2">
                                        <IonCard>
                                            <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2Fwatch%2FActionFilmmakingonYourPhone-UrbanActionShowcase2020.JPG?alt=media"} onClick={()=> window.open("https://youtu.be/AubmCV3ZyyQ","_blank")}/>
                                        </IonCard>
                                    </IonSlide>
                                    <IonSlide key="irlFeed3">
                                        <IonCard>
                                            <img src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/app%2Fwatch%2Fhow_to_journey_challenges_playlist.png?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UN6jo87MdcP6wUvGlv1B79K","_blank")}
                                            />
                                        </IonCard>
                                    </IonSlide>
                                    <IonSlide key="playlistIDX">
                                        <IonCard>

                                            {/*<iframe style={{width:"100%", height:"100%"}} className="video-wrapper"*/}
                                            {/*        src="https://www.youtube.com/embed/videoseries?list=PLXA2PxwVM_UN6jo87MdcP6wUvGlv1B79K"*/}
                                            {/*        title="Journey Challenges" frameBorder="0"*/}
                                            {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                            {/*        allowFullScreen></iframe>*/}

                                            <IonImg src={"https://firebasestorage.googleapis.com/v0/b/myappjfh.appspot.com/o/brand%2FJOURNEY_CHALLENGE_2.jpg?alt=media"} onClick={()=> window.open("https://youtube.com/playlist?list=PLXA2PxwVM_UN6jo87MdcP6wUvGlv1B79K","_blank")}/>
                                        </IonCard>
                                    </IonSlide>
                                     </>
                                    :<></>}
                        </>
                    )
                })}

            </IonSlides>
        );
    };
}
export default IRLContentFeed;
