import {firestore} from "../../firebaseConfig";
import {removeAllyRef} from "./allyrival";


export function createRelationship(rivalRequestData) {
    let newRivalRequestRef = firestore.collection("relationshipRequests").doc();

// later...
    newRivalRequestRef.set(rivalRequestData).then((docRef) => {
        console.log("Document written with ID: ", docRef);
        window.location.reload(true);
    })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
}


export function updatePendingRequests(userID) {
    console.log("update pending requests", userID);
    let userDocRef = firestore.collection('users').doc(userID);
    let userDocRefOps = userDocRef.set({
        pendingRivalRequests: true,
    }, {merge: true});

    userDocRefOps.then(() => {
        //window.location.reload(false);
        console.log("notify user pending requests");
    });
}

export function updateNoPendingRequests(userID) {
    let userDocRef = firestore.collection('users').doc(userID);
    let userDocRefOps = userDocRef.set({
        pendingRivalRequests: false,
    }, {merge: true});

    userDocRefOps.then(() => {
        //window.location.reload(false);
        console.log("notify user pending requests");
    });
}


export function rejectWithdrawRival(rivalRequestId) {
    //delete record
    console.log("rival request id", rivalRequestId);

    let removeRivalRef = firestore.collection('relationshipRequests').doc(rivalRequestId);
    let rivalOps = removeRivalRef.delete();


    rivalOps.then(() => {
        console.log("Document successfully deleted!");
        window.location.reload(true);
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
}


export function confirmRival(rivalRequestId) {
    //update status to approved
    console.log("rival request id", rivalRequestId);
    let rivalRequestRef = firestore.collection('relationshipRequests').doc(rivalRequestId);
    let rivalOps = rivalRequestRef.update({
        status: 'approved',
    });
    rivalOps.then(() => {
        console.log("confirmed rival request");
        //remove ally
        window.location.reload(true);
    });
}


export async function getRivalsArray(userId) {
    let rivalsOut = firestore.collection('relationshipRequests').where('senderId', '==', userId).get();
    let rivalsIn = firestore.collection('relationshipRequests').where('rivalId', '==', userId).get();

    const [rivalsOutSnapshot, rivalsInSnapshot] = await Promise.all([
        rivalsOut,
        rivalsIn
    ]);


    const rivalsOutArray = rivalsOutSnapshot.docs;
    const rivalsInArray = rivalsInSnapshot.docs;

    const rivalsArray = rivalsOutArray.concat(rivalsInArray);

    return rivalsArray;
}


export function findRelationship(user1, user2) {
    let relationshipQuery = firestore.collection('relationshipRequests').where('rivalId', '==', user1).where('senderId', '==', user2).get();
    relationshipQuery.then(function(relationshipDoc)  {
       console.log('relationshipDoc', relationshipDoc);
    });
}

export function updateRelationshipToAlly(relationRequestId) {
    console.log("relationRequestId", relationRequestId);
    let rivalRequestRef = firestore.collection('relationshipRequests').doc(relationRequestId);
    let rivalOps = rivalRequestRef.set({
        status: 'approved',
        relationshipType: 'ally',
    }, {merge:true});
    rivalOps.then(() => {
        console.log("confirmed rival request");
        //remove ally
        window.location.reload(true);
    });
}

export function removeAllyRelationship(relationshipRequestId) {
    //delete record
    console.log("rival request id", relationshipRequestId);

    let removeAllyRef = firestore.collection('relationshipRequests').doc(relationshipRequestId);
    let relationshipOps = removeAllyRef.delete();

    relationshipOps.then(() => {
        console.log("Document successfully deleted!");
        window.location.reload(true);
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
}

export function updateRelationshipToRival(relationRequestId) {
    console.log("relationRequestId", relationRequestId);
    let rivalRequestRef = firestore.collection('relationshipRequests').doc(relationRequestId);
    let rivalOps = rivalRequestRef.set({
        status: 'pending',
        relationshipType: 'rival',
    }, {merge:true});
    rivalOps.then(() => {
        console.log("confirmed rival request");
        //remove ally
        window.location.reload(true);
    });
}

export function updateToRival(relationshipRequestId, senderData, receiverData) {
    console.log("sender data", senderData);
    console.log("receiver data", receiverData);
    let rivalRequestRef = firestore.collection('relationshipRequests').doc(relationshipRequestId);
    let rivalOps = rivalRequestRef.set({
        senderId: senderData['uid'],
        senderName: senderData['senderName'],
        senderPhotoURL: senderData['photoURL'],
        rivalId: receiverData['rivalId'],
        rivalName: receiverData['rivalName'],
        rivalPhotoUrl: receiverData['rivalPhotoUrl'],
        status: 'pending',
        relationshipType: 'rival',
    }, {merge:true});
    rivalOps.then(() => {
        console.log("confirmed rival request");
        //remove ally
        window.location.reload(true);
    });
}