import React, {useRef} from 'react';
import { IonSlides, IonContent, IonPage, IonRow, IonCol, IonImg, IonThumbnail, IonSlide, IonHeader, IonToolbar, IonRouterLink, IonButton, IonText, IonBackButton, IonButtons, IonIcon} from '@ionic/react';
import ApprovedUserScreen1 from './ApprovedUserScreen1';
import ApprovedUserScreen2 from './ApprovedUserScreen2';
import ApprovedUserScreen3 from './ApprovedUserScreen3';
import ApprovedUserScreen4 from './ApprovedUserScreen4';
import ApprovedUserScreen6 from './ApprovedUserScreen6';
import ApprovedUserScreen7 from './ApprovedUserScreen7';
import { chevronBackSharp, chatbubbles } from 'ionicons/icons';
import authWrapper from "../../components/authWrapper";
import Header from "../../components/Header";
import ApprovedUserScreen41 from './ApprovedUserScreen41';
import ApprovedUserScreen42 from './ApprovedUserScreen42';
import ApprovedUserScreen43 from './ApprovedUserScreen43';
import {toast} from "../../toast";


const slideOpts = {
  effect: 'cube',
  zoom: false
};

interface WalkthroughProps {
    user:any;
}
const Walkthrough: React.FC<WalkthroughProps> = (props) => {

  function enterWurld() {
     // window.location.href='/premiumMissions';
    window.location.href='/welcomeWurld';
    //window.location.href='/wurld';
  }

    const mySlides = useRef(null);

    const onBtnClicked = async (direction: string) => {

        const swiper = await mySlides.current.getSwiper();
        if (direction === 'next') {
            if (swiper.slideNext()) {
                await swiper.slideNext();
            }
           else {
                window.location.href='/welcomeWurld';
            }
        }
        else if (direction === 'prev') {
            await swiper.slidePrev();
        }
    }

    return (

    <IonPage id="main">
       <Header/>
       <IonContent className="content-bg ion-padding-bottom">

          <IonSlides pager options={slideOpts} style={{'height':'90%'}} ref={mySlides}>
            <IonSlide>
               <ApprovedUserScreen1/>
            </IonSlide>

              <ApprovedUserScreen2 user={props.user}/>

            <IonSlide>
              <ApprovedUserScreen3 user={props.user}/>
            </IonSlide>
            <IonSlide>
              <ApprovedUserScreen4 user={props.user}/>
            </IonSlide>
              <IonSlide>
                  <ApprovedUserScreen41 user={props.user}/>
              </IonSlide>
              <IonSlide>
                  <ApprovedUserScreen42 user={props.user}/>
              </IonSlide>
            <IonSlide>
              <ApprovedUserScreen43 user={props.user}/>
            </IonSlide>
            <IonSlide>
              <ApprovedUserScreen6 user={props.user}/>
            </IonSlide>
            <IonSlide>
              <ApprovedUserScreen7/>
            </IonSlide>
          </IonSlides>

           <div style={{textAlign: 'center'}}>
                <IonButton onClick={()=>onBtnClicked('prev')}>
                    PREV
                </IonButton>
               <IonButton onClick={()=>onBtnClicked('next')}>
                    NEXT
               </IonButton>
           </div>
          <IonRow className="ion-justify-content-center">
              <IonButton onClick={()=> enterWurld()} className="appBtn">
                <IonText className="appTextMain">Skip for now</IonText>
              </IonButton>

          </IonRow>

       </IonContent>
    </IonPage>
  );
};


export default authWrapper(Walkthrough);
