
import React from 'react';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {Redirect, Route} from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* fonts specific */
import './theme/fonts.css';
/* Theme variables */
import './theme/variables.css';
import Menu from './components/Menu';
import Home from './pages/Home';
import Hire from './pages/Hire';
import Profile from './pages/User/Profile';
import Login from './pages/Login';
import Register from './pages/Register';

import Client from './pages/PreRegistration/Client';

import Support from './pages/SideMenu/Support';

import TermsPage from './pages/SideMenu/TermsPage';
import ApprovalPending from './pages/ApprovalPending';
import ApprovedUserScreen1 from './pages/Walkthrough/ApprovedUserScreen1';

import HeroDelivery from './pages/HeroDelivery';
import ClientDelivery from './pages/ClientDelivery';
import ClientDeliveryReview from './pages/ClientDeliveryReview';
import PreRegistration1 from './pages/PreRegistration/PreRegistration1';
import PrivacyPolicy from './pages/SideMenu/PrivacyPolicy';
import RegisterClient from './pages/RegisterClient';
import Contacts from './pages/Contacts';
import WatchPage from './pages/Watch/WatchPage';
import Missions from './pages/Missions/Missions';
import MissionDeliveryChecklist from './pages/Missions/MissionDeliveryChecklist';
import PreRegistration2 from './pages/PreRegistration/PreRegistration2';
import Walkthrough from './pages/Walkthrough/Walkthrough';
import ApprovedUserScreen2 from './pages/Walkthrough/ApprovedUserScreen2';
import ApprovedUserScreen3 from './pages/Walkthrough/ApprovedUserScreen3';
import ApprovedUserScreen4 from './pages/Walkthrough/ApprovedUserScreen4';
import ApprovedUserScreen6 from './pages/Walkthrough/ApprovedUserScreen6';
import ApprovedUserScreen7 from './pages/Walkthrough/ApprovedUserScreen7';
import Account from './pages/SideMenu/Account';
import Rules from './pages/SideMenu/Rules';
import About from './pages/SideMenu/About';
import Terms from './pages/SideMenu/Terms';
import Wurld from './pages/Wurld/Wurld';
import MissionsFeed from './pages/Wurld/MissionsFeed';
import ChallengeListItemDetail from './pages/Challenges/ChallengeListItemDetail';
import TrainingPage from './pages/Challenges/TrainingPage';
import DevelopmentPage from './pages/Challenges/DevelopmentPage';
import Challenges from './pages/Challenges/Challenges';
import Hero from './pages/PreRegistration/Hero';
import MissionDeliveryReview from './pages/Missions/MissionDeliveryReview';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import CastChat from './pages/Chat/CastChat';
import Users from './pages/Chat/Users';
import PasswordChangeForm from './pages/ChangePassword/PasswordChangeForm';
import RecorderScreen from './pages/RecorderScreen';
import Allies from './pages/User/Allies';
import Rivals from './pages/User/Rivals';
import ViewAssociation from "./pages/User/ViewAssociation";
import ProfileVideoReview from "./pages/PreRegistration/ProfileVideoReview";
import Inbox from "./pages/Chat/Inbox";
import ChallengeDeliveryReview from "./pages/Challenges/ChallengeDeliveryReview";
import ReportRequest from "./pages/SideMenu/ReportRequest";
import MessageDetail from "./pages/Chat/MessageDetail";
import ApprovedUserScreen41 from './pages/Walkthrough/ApprovedUserScreen41';
import ApprovedUserScreen42 from './pages/Walkthrough/ApprovedUserScreen42';
import ApprovedUserScreen43 from './pages/Walkthrough/ApprovedUserScreen43';
import ChatHomePage from './pages/Chat/ChatHomePage';
import Conversations from './pages/Chat/Conversations';
import StartConversation from './pages/Chat/StartConversation';
import ConversationDetail from './pages/Chat/ConversationDetail';
import ConversationThread from './pages/Chat/ConversationThread';
import ProfileVideoItem from './pages/User/ProfileVideoItem';
import CompletedByList from './components/CompletedByList';
import LoginHero from './pages/LoginHero';
import SendContent from "./pages/Chat/SendContent";
import ViewAllies from './pages/User/ViewAllies';
import ViewRivals from './pages/User/ViewRivals';
import PremiumMissions from './pages/Missions/PremiumMissions';
import Events from './components/Events';
import Assets from './components/Assets';
import TrainingChallenges from './pages/Challenges/TrainingChallenges';
import PremiumChallenges from './pages/Challenges/PremiumChallenges';
import DevelopmentChallenges from "./pages/Challenges/DevelopmentChallenges";
import JoinTestTeam from "./pages/JoinTestTeam";
import ViewContent from './pages/AdminView/ViewContent';
import AccountEdit from "./pages/SideMenu/AccountEdit";
import Countdown from "./components/Countdown";
import Store from "./pages/Store";
import BrandAdmin from './pages/AdminView/BrandAdmin';
import ViewChangeRequests from './pages/AdminView/ViewChangeRequests';
import UserAdminView from './pages/AdminView/UserAdminView';
import HomeWurld from "./pages/Wurld/HomeWurld";
import VideoContainer from "./components/Player/VideoContainer";
import UGCTrainingListPage from "./pages/Challenges/UGCTrainingListPage";
import UGCDevelopmentListPage from "./pages/Challenges/UGCDevelopmentListPage";
import CreateChallenge from "./pages/Challenges/CreateChallenge";
import CreateChallengeItem from "./pages/Challenges/CreateTrainingChallengeItem";
import CreateTrainingChallengeItem from "./pages/Challenges/CreateTrainingChallengeItem";
import CreateDevChallengeItem from "./pages/Challenges/CreateDevChallengeItem";
import UGChallengeDeliveryReview from "./pages/Challenges/UGChallengeDeliveryReview";

const App: React.FC = () => {

  return (
    <IonApp>
      <IonReactRouter>

          <Menu />
          <IonRouterOutlet id="main">

                {/*<Route path="/" component={Home} exact={true} />*/}

                <Route path="/login" component={Login} exact={true} />
                <Route path="/loginHero" component={LoginHero} exact={true} />

                <Route path="/" render={() => <Redirect to="/login" />} exact={true} />

                <Route path="/recorderScreen" component={RecorderScreen} exact={true}/>
                <Route path="/register" component={Register} />
                <Route path="/registerClient" component={RegisterClient} />
                <Route path="/forgotPassword" component={ForgotPassword} exact={true} />
                <Route path="/home" component={Home} exact={true} />
                <Route path="/registration" component={PreRegistration1} exact={true} />
                {/*<Route path="/preRegistration11" component={PreRegistration11} exact={true} />*/}

                <Route path="/chooseRole" component={PreRegistration2} exact={true} />
                <Route path="/completedByList" component={CompletedByList} exact={true} />

                <Route path="/wurld" component={Wurld} />
                <Route path="/welcomeWurld" component={HomeWurld}/>
                <Route path="/watch" component={WatchPage} />
                <Route path="/hire" component={Hire} />
                <Route path="/challenges" component={Challenges} />
                <Route path="/createChallenge" component={CreateChallenge} />
                <Route path="/createTrainingChallengeItem" component={CreateTrainingChallengeItem} />
                <Route path="/createDevChallengeItem" component={CreateDevChallengeItem} />
                <Route path="/ugChallengeReview" component={UGChallengeDeliveryReview} />

                <Route path="/trainingChallenges" component={TrainingChallenges} />
                <Route path="/developmentChallenges" component={DevelopmentChallenges} />
                <Route path="/premiumChallenges" component={PremiumChallenges} />
                <Route path="/ugcTrainingChallenges" component={UGCTrainingListPage} />
                <Route path="/ugcDevelopmentChallenges" component={UGCDevelopmentListPage} />


                <Route path="/profile" component={Profile}  />

                {/*<Route path="/viewAssociation" component={ViewAssociation} exact={true}/>*/}

                <Route path="/viewAssociation/:uid" component={ViewAssociation} exact={true}/>
                <Route path="/viewAssociation/:uid/:relationship" component={ViewAssociation}/>
                <Route path="/viewAssociation/:uid/:relationship/:loggedInUser" component={ViewAssociation} exact={true}/>

                <Route path="/hero" component={Hero} exact={true} />
                <Route path="/client" component={Client} exact={true} />

                <Route path="/profileVideoItem" component={ProfileVideoItem} exact={true} />
                <Route path="/profileVideoReview" component={ProfileVideoReview} exact={true} />

                <Route path="/missionsFeed" component={MissionsFeed} exact={true} />
                <Route path="/pages/rules" component={Rules} exact={true} />
                <Route path="/pages/about" component={About} exact={true} />
                <Route path="/pages/termsPage" component={TermsPage} exact={true} />
                <Route path="/pages/terms" component={Terms} exact={true} />
                <Route path="/pages/account" component={Account} exact={true} />
                <Route path="/account" component={AccountEdit} exact={true} />

                <Route path="/pages/support" component={Support} exact={true} />
                <Route path="/approvalPending" component={ApprovalPending} exact={true} />
                <Route path="/walkthrough" component={Walkthrough} exact={true} />
                <Route path="/approvedUserScreen1" component={ApprovedUserScreen1} exact={true} />
                <Route path="/approvedUserScreen2" component={ApprovedUserScreen2} exact={true} />
                <Route path="/approvedUserScreen3" component={ApprovedUserScreen3} exact={true} />
                <Route path="/approvedUserScreen4" component={ApprovedUserScreen4} exact={true} />
                <Route path="/approvedUserScreen41" component={ApprovedUserScreen41} exact={true} />
                <Route path="/approvedUserScreen42" component={ApprovedUserScreen42} exact={true} />
                <Route path="/approvedUserScreen43" component={ApprovedUserScreen43} exact={true} />
                <Route path="/approvedUserScreen6" component={ApprovedUserScreen6} exact={true} />
                <Route path="/approvedUserScreen7" component={ApprovedUserScreen7} exact={true} />
                <Route path="/heroDelivery" component={HeroDelivery} exact={true} />
                <Route path="/clientDelivery" component={ClientDelivery} exact={true} />
                <Route path="/clientDeliveryReview" component={ClientDeliveryReview} exact={true} />
                <Route path="/privacyPolicy" component={PrivacyPolicy} />
                <Route path="/conversations" component={Conversations} exact={true} />
                <Route path="/contacts" component={Contacts} exact={true} />
                <Route path="/watchPage" component={WatchPage} exact={true} />
                <Route path="/missions" component={Missions} exact={true} />
                <Route path="/premiumMissions" component={PremiumMissions} exact={true} />


                <Route path="/missionDeliveryChecklist" component={MissionDeliveryChecklist} exact={true}/>
                <Route path="/challengeListItemDetail" component={ChallengeListItemDetail} exact={true}/>

                <Route path="/missionDeliveryReview" component={MissionDeliveryReview} exact={true} />
                <Route path="/challengeDeliveryReview" component={ChallengeDeliveryReview} exact={true} />

                <Route path="/cast" component={Users} />
                <Route path="/events" component={Events} />
                <Route path="/assets" component={Assets} />

                <Route path="/users" component={Users} />

                <Route path="/allies/:userId" component={Allies} />
                <Route path="/rivals/:userId" component={Rivals} />
                <Route path="/viewAllies/:userId/:currentUserId" component={ViewAllies} />
                <Route path="/viewRivals/:userId/:currentUserId" component={ViewRivals} />

                <Route path="/chat" component={ChatHomePage} />

                <Route path="/castChat" component={CastChat} />
                <Route path="/inbox" component={Inbox} />
                <Route path="/startConversation" component={StartConversation} exact={true} />
                <Route path="/conversationDetail" component={ConversationDetail} exact={true}/>
                <Route path="/conversationThread" component={ConversationThread} exact={true}/>

                <Route path="/sendContent" component={SendContent} exact={true}/>

                <Route path="/messageDetail" component={MessageDetail} exact={true}/>

                <Route path="/reportRequest" component={ReportRequest} exact={true} />
                <Route path="/changePassword" component={PasswordChangeForm} exact={true} />
                <Route path="/pages/joinTestTeam" component={JoinTestTeam} exact={true} />

                <Route path="/brandAdmin" component={BrandAdmin} exact={true} />
                <Route path="/countdown" component={Countdown} exact={true} />
                <Route path="/recorder" component={RecorderScreen} exact={true} />
                <Route path="/merch" component={Store} exact={true} />
                <Route path="/userAdmin" component={UserAdminView} exact={true} />

                <Route path="/videoPlayer" component={VideoContainer} exact={true}/>

          </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
    //</UserProvider>
  );
};

export default App;
