import React, {useState, useEffect} from 'react';
import {
    IonCol,
    IonRow,
    IonContent,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonText,
    IonButton,
    IonImg,
    IonCard,
    IonAlert, IonModal, IonHeader, IonFooter
} from '@ionic/react';
import './Profile.css';
import Footer from '../../components/Footer';


import {  firestore, storageRef } from '../../firebaseConfig';

import { RouteComponentProps, useHistory, withRouter } from 'react-router';

import authWrapper from '../../components/authWrapper';
import VideoListItem from '../../components/VideoListItem';
import {ally, rival} from "./allyrival";
import {selectSegment} from "./profileSegment";
import DeliveryModuleBtnContainer from "../Wurld/DeliveryModuleBtnContainer";
import Header from "../../components/Header";
import MissionDeliveryComponent from '../../components/MissionDeliveryComponent';
import { sendJoinTestTeamEmail } from '../../util/sendEmail';
import ProfileVideo from '../../components/ProfileVideo';
import { getPictureAddress } from '../../util/avatarUtils';
import { findOrCreateConvo } from '../Chat/chat';
import {
    confirmRival,
    createRelationship, findRelationship, getRivalsArray,
    rejectWithdrawRival, updateNoPendingRequests,
    updatePendingRequests, updateRelationshipToAlly, updateRelationshipToRival, updateToRival
} from "./relationships";
import { toast } from '../../toast';

const centeringContainerStyle = {
    height: '100%',
    width: '100%',

    justifyContent: 'center',
    alignItems: 'center',
}

interface ViewAssociationProps extends RouteComponentProps <{
    uid: any,
    relationship: string,
    loggedInUser: any,
}> {}


const ViewAssociation: React.FC<ViewAssociationProps> = (props) => {

    const [missionsView, setMissionsView] = useState([]);
    const [challengesView, setChallengesView] = useState([]);
    const [missionsCreatedView, setMissionsCreatedView] = useState([]);

    const [userView, setUserView] = useState<any>();
    const [showAlliesList, setShowAlliesList] = useState(false);
    const [showRivalsList, setShowRivalsList] = useState(false);
    const [isAlly, setIsAlly] = useState(false);
    const [isRival, setIsRival] = useState(false);

    const [viewRivalDoc, setViewRivalDoc] = useState<any>();
    const [rivalStatus, setRivalStatus] = useState('Rival');
    const [rivalPendingCount, setRivalPendingCount] = useState<number>();
    const [rivalsApprovedCount, setRivalsApprovedCount] = useState<number>();
    const [alliesCount, setAlliesCount] = useState<number>();

    const [showAllyPrompt, setShowAllyPrompt] = useState(false);
    const [showRivalPrompt, setShowRivalPrompt] = useState(false);
    const [showApprovalPrompt,setShowApprovalPrompt] = useState(false);
    const [alliesList, setAlliesList] = useState<any>();
    const [rivalsList, setRivalsList] = useState<any>();


    const {uid, relationship, loggedInUser} = props.match.params;

    console.log("logged in user 64", loggedInUser);

    const {currentUserLoggedIn} = props['user'].uid;
    const {currentUserLogged} = props['user'];

    const history = useHistory();


    if (uid === props['user'].uid) {
        //console.log("your own profile");
        window.location.href='/profile';
    }
    const [showJoin, setShowJoin] = useState(false);

    const joinTestTeam = () => {
        console.log("send email ");
        sendJoinTestTeamEmail(currentUserLogged);
        setShowJoin(false);
    }


    console.log("current user logged", currentUserLogged, "logged in");

    console.log("logged in  user logged from props id", loggedInUser, "logged in");
    console.log("props", props, "logged in");

    console.log("sender id ", props['user'].uid, "logged in");

    useEffect(() => {
        let isSubscribed = true;

        let rivalsList = [];

        console.log("rivalId!!!!!",uid);
        console.log("senderId!!!!!",props['user'].uid);



        getRivalsArray(uid).then(snapshotRivals => {
            let pendingNo = 0;
            let approvedNo = 0;
            let alliesNo = 0;

            snapshotRivals.forEach(snapshotRival => {
                // if (suggestionRivalDoc.data() && suggestionRivalDoc.data()['role'] && suggestionRivalDoc.data()['role'] != 'client') {
                // rivalsList.push({...snapshotRival.data(), uid: snapshotRival.id})
                //console.log("snapshotRival", JSON.stringify(snapshotRival.data()));
                if ((snapshotRival.data()['rivalId'] === uid && snapshotRival.data()['senderId'] === props['user'].uid) || (snapshotRival.data()['rivalId'] === props['user'].uid && snapshotRival.data()['senderId'] === uid))  {
                    setViewRivalDoc({...snapshotRival.data(), uid: snapshotRival.id});
                }
                if (snapshotRival.data()['status']==='pending' && snapshotRival.data()['relationshipType'] === 'rival') {
                    pendingNo = pendingNo+1;
                }
                else if (snapshotRival.data()['status'] ==='approved' && snapshotRival.data()['relationshipType'] === 'rival') {

                     rivalsList.push(snapshotRival.data());
                    //     filteredRivalsList.push(doc.data()['rivals']);
                    approvedNo = approvedNo+1;
                }
                else if (snapshotRival.data()['relationshipType'] === 'ally' && snapshotRival.data()['status'] === 'approved'){
                    alliesNo = alliesNo + 1;
                }
            });
            setRivalPendingCount(pendingNo);
            setRivalsApprovedCount(approvedNo);
            setAlliesCount(alliesNo);

            if (pendingNo > 0) {
                setIsRival(false);
                setRivalStatus('pending');
            }
            else if (approvedNo > 0) {
                setIsRival(true);
            }

            console.log("pending", pendingNo);
            console.log("approvedNo", approvedNo);
            console.log("allies", alliesCount);
        });

        //console.log("view asoc", findRelationship(uid, props['user'].uid));
        // if (relationship && relationship !== undefined && relationship != '') {
            console.log("relationship", relationship);


        // }


        const userViewRef = firestore.collection('users').doc(uid);
        userViewRef.get().then((doc)=> {
            const userView = {id: doc.id, ...doc.data()};

            setUserView(userView);

            if (userView['profileVideo']!=='') {
                storageRef.child(userView['profileVideo']).getDownloadURL().then(function(url) {

                    let img = document.getElementById('videoContainer');
                    url= url +"#t=00:00:01";
                    img.setAttribute("src", url);
                }).catch(function(error) {
                    console.log('error getting content');
                });
            }


            const userContentListRef = firestore.collection('content').where("completedByUserId", "==", userView['id']).where('publicContent', '==', true).orderBy('timeCreated', 'desc');

            userContentListRef.onSnapshot(snapshot => {
                let userContentList = [];

                snapshot.forEach(doc =>
                    userContentList.push({ ...doc.data(), uid: doc.id }),
                );
                //console.log("missions view", userContentList);

                let challengeList = userContentList.filter(item => item.contentType !== 'mission');
                let missionList = userContentList.filter(item => item.contentType === 'mission');
                setMissionsView(missionList);
                setChallengesView(challengeList);
            });

            const missionsCreatedRef = firestore.collection('missions').where("submittedBy", "==", userView['id']).where('publicContent','==', true).orderBy('timeCreated', 'desc');
            missionsCreatedRef.onSnapshot(snapshot => {
                let missionsCreatedList = [];

                snapshot.forEach(doc =>
                    missionsCreatedList.push({ ...doc.data(), uid: doc.id }),
                );

                setMissionsCreatedView(missionsCreatedList);
            });
        });

        return () => {isSubscribed = false}
    }, [uid]);


    function changeSegmentContent(selectedSegment) {
        selectSegment(selectedSegment);
    }

    function addAlly(rivalDoc, userID, removeRival) {

        if (rivalDoc !== undefined) {
            updateRelationshipToAlly(rivalDoc.uid);
        }
        else {
            let allyRequestData = {
                senderId: props['user'].uid,
                senderName: props['user'].characterName,
                senderPhotoURL: props['user'].photoURL,
                rivalId: userID,
                rivalPhotoUrl: userView['photoURL'],
                rivalName: userView['characterName'],
                status: 'approved',
                relationshipType: 'ally',
            }
            createRelationship(allyRequestData);
        }
    }



    function addRival(userId, removeAlly) {

        let rivalRequestData = {
            senderId: props['user'].uid,
            senderName: props['user'].characterName,
            senderPhotoURL: props['user'].photoURL,
            rivalId: userId,
            rivalPhotoUrl: userView['photoURL'],
            rivalName: userView['characterName'],
            status: 'pending',
            relationshipType: 'rival',
        }

        //console.log("rival request", rivalRequestData);

        createRelationship(rivalRequestData);
        //console.log("user id", userId);

        updatePendingRequests(userId);
        updatePendingRequests(props['user'].uid);
    }

    function handleRivalStatus(rivalStatus, userId) {
        console.log("rival status", rivalStatus);

       if (rivalStatus === undefined) {
           setShowRivalsList(true);
          // addRival(userId, true);
       }
       else if (rivalStatus.status === 'pending') {
           console.log("approve");
           setShowApprovalPrompt(true);
       }

        // if (rivalStatus === 'Rival')
        // {
        //     setShowRivalsList(true);
        //
        // }
        // else if (rivalStatus === 'pending') {
        //     setShowApprovalPrompt(true);
        // }
        //
        // else {
        //     console.log('rival status remove', rivalStatus);
        //
        //     console.log('user view', userView);
        //
        //     console.log('rival ', props['user']);
        //
        //    addRival(userId, true);
        // }

    }

    function confirmAndUpdatePending(rivalDoc, uid) {
        confirmRival(rivalDoc.uid);
        updateNoPendingRequests(uid);
        updateNoPendingRequests(rivalDoc.rivalId);

    }

    function rejectAndUpdatePending(rivalDoc, uid) {
        //console.log("rival doc", rivalDoc);
        rejectWithdrawRival(rivalDoc.uid);
        updateNoPendingRequests(uid);
        updateNoPendingRequests(rivalDoc.rivalId);
    }

    function updateRelationship(rivalDoc) {
        console.log("rival doc", rivalDoc);
        if (rivalDoc) {
            updateRelationshipToAlly(rivalDoc.uid);
        }

    }

    function changeRelationship(rivalDoc, userId) {
        console.log("rival doc", rivalDoc);
        if (rivalDoc === undefined) {
            addRival(userId, true);
        }
        else {
            console.log("rival Doc",  JSON.stringify(rivalDoc));
            //updateRelationshipToRival(rivalDoc.uid);
            // senderId: senderData['uid'],
            //     senderName: senderData['senderName'],
            //     senderPhotoURL: senderData['photoURL'],
            //     rivalId: receiverData['uid'],
            //     rivalName: receiverData['rivalName'],
            //     rivalPhotoUrl: receiverData['rivalPhotoUrl'],
            //
            let senderData = {
                uid: props['user'].uid,
                senderName: props['user'].characterName,
                photoURL: props['user'].photoURL,
            };
            let receiverData = {
                rivalId: userId,
                rivalPhotoUrl: userView['photoURL'],
                rivalName: userView['characterName'],
            };
            updateToRival(rivalDoc.uid, senderData, receiverData);
        }
    }


    return (

        <IonPage>
            <Header/>
            <IonContent
                scrollEvents={true}
                onIonScrollStart={() => {}}
                onIonScroll={() => {}}
                onIonScrollEnd={() => {}} scrollX>
                <div style={centeringContainerStyle}>
                    <IonModal isOpen={showJoin}
                              cssClass='join-custom-class'
                              onDidDismiss={() => setShowJoin(false)}>
                        <IonHeader>
                            <IonRow style={{padding: 10}}>
                                <IonText className="appTextMain center">
                                    {(userView && userView['testTeamMember'] !== undefined && userView['testTeamMember'])? 'You can access this section in the next app test session.': 'To gain access to this section, join the JFH App Test Team.'}
                                </IonText>
                            </IonRow>
                        </IonHeader>
                        <IonContent className='join-custom-class'
                                    scrollEvents={true}
                                    onIonScrollStart={() => {
                                    }}
                                    onIonScroll={() => {
                                    }}
                                    onIonScrollEnd={() => {
                                    }} scrollX scrollY>

                            {(userView && userView['testTeamMember'] !== undefined && userView['testTeamMember'])
                                ?<><IonRow><IonCol></IonCol></IonRow>
                                    <IonRow><IonCol></IonCol></IonRow>
                                    <IonRow>
                                        <IonText className="appTextMain center">Check your email for the invite! </IonText>
                                    </IonRow>
                                    <IonRow><IonCol></IonCol></IonRow>
                                </>
                                :<IonImg src="/assets/img/JoinTestTeam.png" style={{height:'150px', width:'300'}}></IonImg>}

                        </IonContent>
                        <IonFooter>
                            <IonRow className="ion-justify-content-center">

                                {userView && userView['testTeamMember'] === undefined  &&
                                <IonButton onClick={joinTestTeam} style={{'--background':'#005494'}} className="center">
                                    <IonLabel className="italic bolder">
                                        Join
                                    </IonLabel>
                                </IonButton>}
                            </IonRow>
                        </IonFooter>
                    </IonModal>
                    <IonRow>
                        <IonCol>
                            {userView && userView['photoURL'] === '' &&
                                <IonImg src={"https://www.gravatar.com/avatar?d=mm&s=140"}></IonImg>
                            }
                            { userView &&
                                userView['photoURL'] !== '' && userView['profileVideo'] === '' &&
                                <IonImg id={'profile'+userView['uid']} src={userView[
                                    'photoURL'] ? getPictureAddress(userView['photoURL'],userView['uid'], 'viewProfile') : "https://www.gravatar.com/avatar?d=mm"}/>
                            }
                            {userView?.profileVideo !== '' &&
                            <ProfileVideo videoPath={userView?.profileVideo} userId={userView?.uid} photoUrl={userView?.photoURL} role={userView?.role} tab={'viewProfile'} index={userView?.role === 'hero' ? userView?.heroNumber : userView?.clientNumber } />

                                // <div className="profileVideo-wrapper">
                            //     <video id="videoContainer" controls poster={userView?.photoUrl} controlsList="nodownload" preload="auto">
                            //         <source src={userView?.profileVideo+"#t=00:00:01"} type="video/mp4" />
                            //     </video>
                            // </div>
                                // <VideoViewer url={userView?.profileVideo} photoUrl={userView?.photoUrl} contentType="video/quicktime"/>
                            }
                        </IonCol>

                        {userView?.role === 'hero' && !userView?.villain  && <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <IonRow ><IonText className="contentTextTitle" color="primary" >NAME: </IonText> <IonText className="contentText" color="primary"> {userView?.heroName}</IonText></IonRow>
                            <IonRow><IonCol></IonCol><IonCol></IonCol> </IonRow>
                            <IonRow><IonText className="contentTextTitle" color="primary"> HERO#: </IonText> <IonText className="contentText" color="primary"> {userView?.heroNumber}</IonText></IonRow>
                            <IonRow><IonCol></IonCol> </IonRow>
                            <IonRow> <IonText className="contentTextTitle" color="primary">TEAM: </IonText> <IonText className="contentText" color="primary"> {userView?.teamName}</IonText></IonRow>
                            <IonRow><IonCol>
                                <IonButton fill="outline" onClick={() => findOrCreateConvo(props['user'], userView, history, 'chat')} style={{'--color':'#005494', fontStyle:'italic'}}>
                                         MESSAGE
                                </IonButton>
                            </IonCol>
                            </IonRow>

                        </div> }
                        {(userView?.role === 'villain' || userView?.villain)  && <div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <IonRow ><IonText className="contentTextTitle" color="primary" >NAME: </IonText> <IonText className="contentText" color="primary"> {userView?.characterName}</IonText></IonRow>
                            <IonRow><IonCol></IonCol><IonCol></IonCol> </IonRow>
                            <IonRow><IonText className="contentTextTitle" color="primary"> HERO#: </IonText> <IonText className="contentText" color="primary"> {userView?.heroNumber}</IonText></IonRow>
                            <IonRow><IonCol></IonCol> </IonRow>
                            <IonRow> <IonText className="contentTextTitle" color="primary">TEAM: </IonText> <IonText className="contentText" color="primary"> {userView?.teamName}</IonText></IonRow>
                            <IonRow><IonCol>
                                <IonButton fill="outline" onClick={() => findOrCreateConvo(props['user'] ? props['user'] : userView, userView, history, 'chat')} style={{'--color':'#005494', fontStyle:'italic'}}>
                                     MESSAGE
                                </IonButton>
                            </IonCol>
                            </IonRow>

                        </div> }
                        {userView?.role === 'client' && <IonCol>
                            <IonRow ><IonText className="contentText" color="primary" >Name: </IonText> <IonText className="contentText" color="primary"> {userView?.clientName}</IonText></IonRow>
                            <IonRow><IonCol></IonCol><IonCol></IonCol> </IonRow>
                            <IonRow><IonText className="contentText" color="primary"> Client #: </IonText> <IonText className="contentText" color="primary"> {userView?.clientNumber}</IonText></IonRow>
                            <IonRow><IonCol></IonCol> </IonRow>
                            <IonRow> <IonText className="contentText" color="primary">Missions: </IonText> <IonText className="contentText" color="primary"> {missionsCreatedView?.length}</IonText></IonRow>
                            <IonRow><IonCol>
                                <IonButton  fill="outline"  className="editProfileButton" onClick={() => findOrCreateConvo(props['user'] ? props['user'] : userView, userView, history, 'chat')} style={{'--color':'#005494', fontStyle:'italic'}}>
                                    MESSAGE
                                </IonButton>
                            </IonCol>
                            </IonRow>

                        </IonCol> }
                    </IonRow>
                    {(userView?.role === 'hero' || userView?.role === 'villain') &&

                    <IonRow className="ion-justify-content-center" style={{flexWrap: 'nowrap'}}>
                        <IonCol className="ion-text-center">
                            {alliesCount > 0 ?
                                <IonButton className="contentText alliesNo" fill="outline" href={`/viewAllies/${userView.id}/${props['user']?.uid}`}>
                                    {alliesCount} </IonButton>
                                : <IonButton className="contentText alliesNo" fill="outline"> 0 </IonButton>
                            }
                        </IonCol>
                        <IonCol className="ion-text-center">

                            {rivalsApprovedCount == 0
                                ?  <IonButton className="contentText alliesNo" fill="outline"> 0 </IonButton>
                                : <IonButton className="contentText alliesNo" fill="outline" href={`/viewRivals/${userView.id}/${props['user']?.uid}`}>
                                     {rivalsApprovedCount}
                                 </IonButton>}

                        </IonCol>
                    </IonRow>
                    }

                    {props['user']?.role === 'client' &&

                    <IonRow className="ion-justify-content-center" style={{flexWrap: 'nowrap'}}>
                        <IonButton className="alliesBtn bolder" onClick={() => toast('You have to be a hero or villain to ally this hero')}>  ALLY  </IonButton>
                        <div style={{
                            marginLeft: -17,
                            marginTop: 4,
                            marginRight: 4,
                            width: 25, height: 42,
                            transform: "skew(20deg)",
                            backgroundColor: 'white',
                            borderWidth: 2, borderStyle: 'solid', borderColor: '#005494',
                            boxShadow: "0px 4px 4px -4px dimgray",
                        }}/>
                        <div style={{
                            marginRight: -17,
                            marginTop: 4,
                            width: 25, height: 42,
                            transform: "skew(20deg)",
                            backgroundColor: '#005494',
                            boxShadow: "-4px 0px 4px -4px dimgray",

                        }}/>

                        <IonButton className="rivalsBtn bolder" onClick={() => toast('You have to be a hero or villain to rival this hero')}>   RIVAL  </IonButton>

                    </IonRow>}

                    {props['user']?.role !== 'client' && (userView?.role === 'hero' || userView?.role === 'villain') &&
                        <IonRow className="ion-justify-content-center" style={{flexWrap: 'nowrap'}}>

                        {(viewRivalDoc && viewRivalDoc.relationshipType === 'ally')
                            ? <IonButton className="alliesBtn italic bolder"  onClick={() => setShowAllyPrompt(true)}>  ALLY  </IonButton>
                            : <IonButton className="alliesBtn" onClick={() => setShowAlliesList(true)}>  Ally  </IonButton>}
                          {/*? <IonButton className="alliesBtn italic bolder"  onClick={() => setShowJoin(true)}>  ALLY  </IonButton>*/}
                          {/*: <IonButton className="alliesBtn" onClick={() => setShowJoin(true)}>  Ally  </IonButton>}*/}
                            <IonAlert
                            isOpen={showAllyPrompt}
                            onDidDismiss={() => setShowAllyPrompt(false)}
                            cssClass='list-popup'
                            header={'JFH'}
                            message={'You are already Allies with this Hero.'}
                            buttons={[
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: blah => {

                                        //console.log('Confirm Cancel: blah', userView.id);
                                    }
                                },
                                {
                                    text: 'OK',
                                    handler: () => {
                                        //addAlly(rivalDoc, userView.id, true);
                                       // updateRelationshipTypeToAlly(rivalDoc,uid);
                                        //ally(userView.id);
                                        //console.log('Confirm Okay', userView.id, this.state.uid);
                                    }
                                }
                            ]}
                        />
                        <IonAlert
                            isOpen={showAlliesList}
                            onDidDismiss={() => setShowAlliesList(false)}
                            cssClass='list-popup'
                            header={'JFH'}
                            message={ (viewRivalDoc && viewRivalDoc.relationshipType==='rival') ? 'Would you like to change your association from Rival to Ally?' : 'Would you like to Ally this Hero?'}
                            buttons={[
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: blah => {

                                        //console.log('Confirm Cancel: blah', userView.id);
                                    }
                                },
                                {
                                    text: 'OK',
                                    handler: () => {
                                       addAlly(viewRivalDoc, userView.id, true);
                                        //updateRelationship(rivalDoc);
                                        //console.log('Confirm Okay', userView.id, this.state.uid);
                                    }
                                }
                            ]}
                        />
                        <div style={{
                            marginLeft: -17,
                            marginTop: 4,
                            marginRight: 4,
                            width: 25, height: 42,
                            transform: "skew(20deg)",
                            backgroundColor: 'white',
                            borderWidth: 2, borderStyle: 'solid', borderColor: '#005494',
                            boxShadow: "0px 4px 4px -4px dimgray",
                        }}/>
                        <div style={{
                            marginRight: -17,
                            marginTop: 4,
                            width: 25, height: 42,
                            transform: "skew(20deg)",
                            backgroundColor: '#005494',
                            boxShadow: "-4px 0px 4px -4px dimgray",

                        }}/>

                        {/*<IonRow>{JSON.stringify(viewRivalDoc)}</IonRow>*/}

                        {viewRivalDoc === undefined &&
                          <IonButton className="rivalsBtn bolder" onClick={() => handleRivalStatus(viewRivalDoc, uid)}>   Rival  </IonButton>
                        }

                        {viewRivalDoc && viewRivalDoc?.status === 'pending'  && viewRivalDoc?.senderId === uid &&
                        // <IonButton className="rivalsBtn bolder" onClick={() => setShowJoin(true)}>  RIVAL  </IonButton>
                        <IonButton className="rivalsBtn bolder" onClick={() => handleRivalStatus(viewRivalDoc, uid)}>   Approve/Reject  </IonButton>
                            /*  : <IonButton className="rivalsBtn" onClick={() => handleRivalStatus(rivalStatus)}>
                                 {rivalDoc.status === 'pending' ? 'Pending/Withdraw' : rivalStatus}
                               </IonButton>*/
                            //     ?<IonButton className="rivalsBtn italic bolder" onClick={() => setShowJoin(true)}>  RIVAL  </IonButton>
                            //    :<IonButton className="rivalsBtn" onClick={() => setShowJoin(true)}>  Rival  </IonButton>
                        }

                        {viewRivalDoc && viewRivalDoc?.status === 'approved' && viewRivalDoc?.relationshipType === 'rival' &&
                             <IonButton className="rivalsBtn italic bolder" onClick={() => setShowRivalPrompt(true)}>  Rival  </IonButton>
                           /*  : <IonButton className="rivalsBtn" onClick={() => handleRivalStatus(rivalStatus)}>
                                {rivalDoc.status === 'pending' ? 'Pending/Withdraw' : rivalStatus}
                              </IonButton>*/
                            //     ?<IonButton className="rivalsBtn italic bolder" onClick={() => setShowJoin(true)}>  RIVAL  </IonButton>
                             //    :<IonButton className="rivalsBtn" onClick={() => setShowJoin(true)}>  Rival  </IonButton>
                        }


                        {viewRivalDoc && viewRivalDoc?.status === 'approved' && viewRivalDoc?.relationshipType === 'ally' &&
                        <IonButton className="rivalsBtn bolder" onClick={() => setShowRivalsList(true)}>  Rival  </IonButton>
                            /*  : <IonButton className="rivalsBtn" onClick={() => handleRivalStatus(rivalStatus)}>
                                 {rivalDoc.status === 'pending' ? 'Pending/Withdraw' : rivalStatus}
                               </IonButton>*/
                            //     ?<IonButton className="rivalsBtn italic bolder" onClick={() => setShowJoin(true)}>  RIVAL  </IonButton>
                            //    :<IonButton className="rivalsBtn" onClick={() => setShowJoin(true)}>  Rival  </IonButton>
                        }

                        {viewRivalDoc && viewRivalDoc?.status === 'pending' && viewRivalDoc?.rivalId === uid &&
                        <>
                            <IonButton className="rivalsBtn" onClick={() => rejectAndUpdatePending(viewRivalDoc, uid)}>
                               Pending/Withdraw
                            </IonButton>
                        </>
                        }
                        {/*{rivalDoc && rivalDoc?.status === 'pending' && rivalDoc?.rivalId !== uid &&*/}
                        {/*<>*/}
                        {/*    <IonButton className="rivalsBtn"*/}
                        {/*               onClick={() => confirmAndUpdatePending(rivalDoc, uid)}> Approve </IonButton>*/}
                        {/*    <IonButton className="rivalsBtn" onClick={() => rejectAndUpdatePending(rivalDoc.uid, uid)} > Reject  </IonButton>*/}
                        {/*</>}*/}


                        <IonAlert
                            isOpen={showRivalPrompt}
                            onDidDismiss={() => setShowRivalPrompt(false)}
                            cssClass='list-popup'
                            header={'JFH'}
                            message={'You are already Rivals with this Hero.'}
                            buttons={[
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: blah => {

                                        //console.log('Confirm Cancel: blah', userView.id);
                                    }
                                },
                                {
                                    text: 'OK',
                                    handler: () => {
                                        //updateRelationship(rivalDoc);
                                        //ally(userView.id);
                                        //console.log('Confirm Okay', userView.id, this.state.uid);
                                    }
                                }
                            ]}
                        />
                        <IonAlert
                            isOpen={showApprovalPrompt}
                            onDidDismiss={() => setShowApprovalPrompt(false)}
                            cssClass='list-popup'
                            header={'JFH'}
                            message={'Would you like to approve or reject this Rival Request?'}
                            buttons={[
                                {
                                    text: 'Reject',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: () => {
                                        rejectAndUpdatePending(viewRivalDoc, uid);
                                        console.log("reject");
                                        //console.log('Confirm Cancel: blah', userView.id);
                                    }
                                },
                                {
                                    text: 'Approve',
                                    handler: () => {
                                        confirmAndUpdatePending(viewRivalDoc, uid);                                        console.log("confirm");
                                    }
                                }
                            ]}
                        />
                        <IonAlert
                            isOpen={showRivalsList}
                            onDidDismiss={() => setShowRivalsList(false)}
                            cssClass='list-popup'
                            header={'JFH'}
                            message={(viewRivalDoc && viewRivalDoc.relationshipType==='ally') ? 'Would you like to change your association from Ally to Rival?': 'Would you like to Rival this Hero?'}
                            buttons={[
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: blah => {
                                        console.log("is ally line 692", isAlly);
                                        //console.log('Confirm Cancel: blah', userView.id);
                                    }
                                },
                                {
                                    text: 'OK',
                                    handler: () => {
                                        changeRelationship(viewRivalDoc, userView.id);
                                    }
                                }
                            ]}
                        />
                    </IonRow>}
                    {userView?.role === 'hero' &&
                    <IonRow style={{marginTop: 15, marginBottom: 10, marginLeft: 2}}>
                        <IonText className="contentTextTitle" color="primary"> ONE-LINER: </IonText>
                        <IonText className="contentText" color="primary"> {userView?.oneLiner} </IonText>
                    </IonRow>
                    }

                    {userView?.role === 'client' &&
                    <IonRow style={{marginTop: 15, marginBottom: 10, marginLeft: 2}}>
                        <IonLabel className="contentTextTitle aboutProfile" color="primary"> ABOUT: </IonLabel>
                        <IonText className="contentTextTitle"> {userView?.about} </IonText>
                    </IonRow>
                    }
                    {userView?.role && <IonSegment value="userMissions" scrollable onIonChange={(e) => changeSegmentContent(e.detail.value as any)}>
                        <IonSegmentButton value="userMissions">
                            <IonLabel className="labelText">Missions</IonLabel>
                        </IonSegmentButton>

                        {userView?.role === "hero" && <IonSegmentButton value="userChallenges">
                            <IonLabel className="labelText">Challenges</IonLabel>
                        </IonSegmentButton>}
                        <IonSegmentButton value="userBackstory">
                            <IonLabel className="labelText">Backstory</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>}
                    <div id="userMissionsFeed" >
                        {missionsView && missionsView.map((missionContent, idx) => {
                            return (

                                <IonCard key={idx} className='missionContainer'>
                                    <VideoListItem contentItem={missionContent} tab="associationMission" enableTogglePrivate={false}/>


                                    <DeliveryModuleBtnContainer deliveryModule={missionContent} loggedInUser={currentUserLogged} tab="associationMission"/>

                                </IonCard>
                            );
                        })}

                        {missionsCreatedView && missionsCreatedView.map((missionCreated, mcvIdx) => {
                            return (
                                <MissionDeliveryComponent key={mcvIdx} mission={missionCreated} showButtons={true} loggedInUser={currentUserLogged ? currentUserLogged: userView} index={mcvIdx} fromPage="viewAssociation" showCompletedSection={false}/>

                            );
                        })}

                    </div>
                        {userView?.role === "hero"  && <IonContent id="userChallengesFeed" scrollX style={{'display':'none'}}>
                            {challengesView && challengesView.map((challenge, idx) => {
                                return (
                                    <IonCard key={idx} className='deliveryModuleContainer'>
                                        <VideoListItem contentItem={challenge} tab="associationChallenge" enableTogglePrivate={false}/>
                                        <DeliveryModuleBtnContainer deliveryModule={challenge} loggedInUser={loggedInUser} tab="associationChallenge"/>
                                    </IonCard>
                                );
                            })}
                    </IonContent>}

                    <IonContent id="userBackstoryFeed" style={{'display':'none'}}>
                        <IonText style={{marginLeft:'10px'}}>{userView?.backstory}</IonText>

                    </IonContent>

                    {userView?.role === 'undefined' && <IonContent><IonText color="primary"> You don't have permission</IonText></IonContent>}
                </div>
            </IonContent>
            <Footer />
        </IonPage>

    );

};

export default authWrapper(withRouter(ViewAssociation));
