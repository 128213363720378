import React, {Component} from 'react';
import {
    IonButtons,
    IonButton,
    IonIcon,
    IonAvatar,
    IonImg,
    IonText,
    IonRow,
    IonCol, IonGrid
} from '@ionic/react';
import {
    caretDown,
    chatbubbleOutline,
    people,
    add,
    send
} from 'ionicons/icons';
import './ModalStyles.css';
import { ContentItem } from '../../models/ContentItem';

import DeliveryModuleComments from "./DeliveryModuleComments";
import PostComment from "./PostComment";
import { storageRef } from '../../firebaseConfig';
import CTADeliveryModule from "../../components/CTADeliveryModule";
import CompletedByList from "../../components/CompletedByList";
import {withRouter} from "react-router-dom";
import SendContentPopover from '../Chat/SendContentPopover';
import { getPictureAddress } from '../../util/avatarUtils';

class DeliveryModuleBtnContainer extends Component {
    state: any;
    props: {
        history: any;
        deliveryModule: ContentItem,
        loggedInUser: any,
        tab: string,
        showPopup: boolean,
    }
    constructor(props: any) {
        super(props);
        this.state = { accorOn: false, deliveryModule: props.deliveryModule, showPeopleList: false, showSharePopover: props.showPopup, loggedInUser: props.loggedInUser, commentsOn: false, comment: '', showJoin: false, tab: props.tab };
    }

    onChange = (event: any) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    togglrAccord = () => {
        this.setState({
            accorOn: !this.state.accorOn
        })

    }

    shoPeopleList = () => {
        this.setState({
            showPeopleList: !this.state.showPeopleList
        })
    }

    showJoinPopup = () => {
        this.setState({
            showJoin: !this.state.showJoin
        })
    }

    showSharePopup = () => {
        this.setState({
            showShare: !this.state.showShare
        })
    }

    addCommentsToList = (comments_list, data, comment) => {
        let that = this;
        let commentObj = data[comment];

    }

    fetchComments = (contentItemId) => {
        let that = this;

    }

    viewProfile = (profileId) => {
        window.location.href=`/viewAssociation/${profileId}`;
    }

    showShareList = () => {
        this.setState({
            showSharePopover: !this.state.showSharePopover
        })
    }


    downloadPictureContent(photoPath, id, tab) {
        return getPictureAddress(photoPath, id, tab);
        // if(photoPath !== '' && photoPath !== undefined) {
        //     storageRef.child(photoPath).getDownloadURL().then(function (pathUrl) {
        //         let img = document.getElementById('profile'+id+tab);
        //         img.setAttribute('src', pathUrl);
        //     }).catch(function (error) {
        //         // Handle any errors
        //     });
        // }
        //
        // return '';
    }

    viewProfileAssociation(id) {
       window.location.href='/viewAssociation/'+id;
    }

    sendContentTo(deliveryModule) {
        //console.log("props wurld", this.props);
        this.props.history.push({
            pathname: '/sendContent',
            state: { contentId: deliveryModule.uid, sender: this.props.loggedInUser, fromPage: this.props.tab}
        });
    }

    render() {

        // console.log("props delivery module btn", this.props);
        // console.log("state delivery module btn", this.state);

        return (
            <>
                <div className={this.props.deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'}>
                    <IonGrid>
                        <IonRow className={this.props.deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} style={{paddingBottom:'10px'}}>
                            <div>
                                <IonAvatar>

                                    <IonImg style={{'height':'50px','width':'50px', 'paddingLeft':'0px', 'marginBottom':'2px'}} id={'profile'+this.props.deliveryModule.uid+this.props.tab} src={this.props.deliveryModule.completedByPhotoUrl ? this.downloadPictureContent(this.props.deliveryModule.completedByPhotoUrl,this.props.deliveryModule.uid, this.props.tab) : "https://www.gravatar.com/avatar?d=mm"}

                                            onClick={() => this.viewProfile(this.props.deliveryModule.completedByUserId)}/>
                                </IonAvatar>
                            </div>
                            <IonCol>
                                <IonRow>
                                    <IonText style={{'fontSize':'18px'}} className="bolder">
                                        {this.props.deliveryModule.completedByHeroName}
                                    </IonText>
                                </IonRow>

                            </IonCol>

                            <IonCol size="5">
                                    <IonButtons>
                                        <IonButton onClick={this.showJoinPopup}>
                                            <IonIcon icon={add} className={this.props.deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} style={{width:'15px', height:'15px'}}/>

                                            {this.state.showJoin &&
                                                <CTADeliveryModule ctaModule={this.props.deliveryModule} user={this.state.loggedInUser} openModalWindow={true}/>
                                            }
                                        </IonButton>
                                        <IonButton onClick={this.shoPeopleList}>

                                            <IonIcon icon={people} className={this.props.deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} style={{width:'15px', height:'15px'}}/>

                                        </IonButton>

                                        <IonButton onClick={this.showShareList}>
                                            <IonIcon icon={send} className={this.props.deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} style={{width:'15px', height:'15px'}}/>
                                        </IonButton>


                                        <IonButton expand="full" fill='clear' onClick={this.togglrAccord} className={this.props.deliveryModule.contentType==='mission'? 'missionContainer': 'deliveryModuleContainer'} >
                                            {this.state.accorOn &&
                                            <IonIcon slot="icon-only" icon={caretDown} style={{width:'15px', height:'15px'}}>
                                            </IonIcon>
                                            }
                                            {!this.state.accorOn &&
                                            <IonIcon slot="icon-only" icon={chatbubbleOutline} style={{width:'15px', height:'15px'}}>
                                            </IonIcon>
                                            }
                                        </IonButton>


                                    </IonButtons>

                                {this.state.showSharePopover &&
                                <SendContentPopover isOpen={this.state.showSharePopover} contentId={this.props.deliveryModule.uid} sender={this.props.loggedInUser} fromPage={'wurld'} sentObjectType={'deliveryModule'} />
                                }

                                {this.state.showPeopleList &&
                                <CompletedByList deliveryModule={this.props.deliveryModule} ctaObjectId={this.props.deliveryModule.objectRefId} showModalWindow={true} fromPage={'wurld'} objectType={'deliveryModule'} loggedInUser={this.props.loggedInUser}/>
                                }

                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonText style={{'fontSize':'14px', 'textAlign':'justify'}}>
                                {this.props.deliveryModule.contentDescription}
                            </IonText>
                        </IonRow>
                    </IonGrid>
                </div>
                <IonGrid style={{paddingLeft:'0px'}}>
                <DeliveryModuleComments deliveryModule={this.props.deliveryModule} loggedInUser={this.props.loggedInUser} commentsOn={this.state.commentsOn} tab={this.props.tab}/>
                <PostComment deliveryModule={this.props.deliveryModule} loggedInUser={this.props.loggedInUser} showInput={this.state.accorOn} commentsOn={this.state.commentsOn} tab={this.props.tab}/>
                </IonGrid>
            </>
        );
    }
}

export default withRouter(DeliveryModuleBtnContainer);
