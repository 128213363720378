
import React, {useEffect, useState} from 'react';
import './ExploreContainer.css';
import { IonCard, IonRow, IonCol, IonButtons, IonButton,  IonIcon, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';

import { Mission } from '../models/Mission';
import { people } from 'ionicons/icons';
import { useHistory} from "react-router-dom";
import CompletedByList from "./CompletedByList";
import CTAVideoListItem from "./CTAVideoListItem";
import CompletedByGrid from './CompletedByGrid';
import {firestore} from "../firebaseConfig";

interface MissionDeliveryProps {
  mission: Mission;
  index: number;
  showButtons: boolean;
  loggedInUser: any;
  fromPage: string;
  showCompletedSection: boolean;
}

const MissionDeliveryComponent: React.FC<MissionDeliveryProps> = ({mission, index, showButtons, loggedInUser, fromPage, showCompletedSection}) => {

    const [showPeopleList, setShowPeopleList] = useState(false);
    const [showCompleted, setShowCompleted] = useState(false);

    const [showShareContent, setShowShareContent] = useState(false);
    const [actionName, setActionName] = useState('accept');

    const history = useHistory();
    const {uid, title, clientName, description, code, contentUrl, posterUrl} = mission;

    const deliveryModuleParam = {objectRefId: mission.uid, contentType:'mission', title: title};

    useEffect(() => {
        let isSubscribed = true;
         console.log("mission id", mission.uid);
        let completedByUserIdArg = loggedInUser.id ? loggedInUser.id : loggedInUser.uid;
        console.log("completedByUserIdArg", completedByUserIdArg);
        let query = firestore.collection('content').where('objectRefId', '==', mission.uid)
            .where('completedByUserId', '==', completedByUserIdArg);

        query.get()
            .then(function(snapshotContent)  {
                let isCompleted = false;
               // console.log(snapshotContent.size);
                if (snapshotContent.size > 0) {
                    isCompleted = true;
                }
                let actionName = 'accept';
                if (isCompleted) {
                    actionName = 'completed';
                }
                setActionName(actionName);
            }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });

        return () => {isSubscribed = false}
    }, [mission]);
    //console.log("loggedInUser", loggedInUser);

    function missionChecklist() {
    console.log("from page", fromPage);
      history.push({
          pathname: '/missionDeliveryChecklist',
          state: {missionId:uid, index, missionTitle:title, heroName: loggedInUser.heroName, userId: loggedInUser.uid, heroId:loggedInUser.heroId, userPhotoURL:loggedInUser.photoURL, fromPage: fromPage }
      });
    }

    const createMission = () => {
        window.location.href="/clientDelivery";
    }

  return (
      <>
     <IonCard className="missionContainer" style={fromPage === 'watch'? {margin: '10px 0px 10px 0px'}:{margin: '5px 5px 5px 5px'}}>
       <CTAVideoListItem contentItem={mission} tab={'missionsList'} enableTogglePrivate={mission.submittedBy===loggedInUser.uid}/>
       {/*<IonGrid style={showButtons ? {'display':'block'} :{'display':'none'}}>*/}
       <IonRow>
           <IonCol>
               <IonLabel style={{'fontSize':'18px'}} className="appTextMain brandText">  {clientName}</IonLabel>
               <IonLabel className="appTextMain brandText">  {description}</IonLabel>
           </IonCol>
       </IonRow>
       <IonRow style={{'float':'right'}}>

             <IonCol size="1">
                 {
                     loggedInUser?.role === 'hero' &&
                     <IonButtons>
                   <IonButton onClick={() => setShowPeopleList(true)}>

                       <IonIcon icon={people} className="missionText"/>

                        <CompletedByList showModalWindow={showPeopleList} ctaObjectId={mission.uid} fromPage={'missions'} deliveryModule={deliveryModuleParam} objectType={'mission'} loggedInUser={loggedInUser}/>

                    </IonButton>

                   {/*<IonButton onClick={() => setShowShareContent(true)}>*/}

                   {/*    <IonIcon icon={send} className="missionText"/>*/}

                   {/*    <SendContentPopover isOpen={showShareContent} contentId={mission.uid} sender={loggedInUser} fromPage={'mission'} sentObjectType={'mission'} />*/}
                   {/*    /!*<CompletedByList showModalWindow={showPeopleList} ctaObjectId={mission.uid} fromPage={'missions'} deliveryModule={deliveryModuleParam}/>*!/*/}

                   {/*</IonButton>*/}
                         {actionName == 'completed'


                            && <IonButton className="completeBtn bolder" style={{'--background':'#005494'}}>
                                 <IonSelect style={{opacity:'1 !important', '--background':'#005494'}} okText="Action" cancelText="Close" placeholder={actionName.toUpperCase()} onIonChange={missionChecklist} interface="action-sheet">
                                     <IonSelectOption value="do_more">Do More</IonSelectOption>
                                 </IonSelect>
                               </IonButton>
                         }
                         {actionName == 'accept' &&
                           <IonButton shape="round" fill="solid" size="default" className="btn-accept-mission bolder"  style={{opacity:'1 !important', '--background':'#66cc33'}} onClick={missionChecklist}> {actionName.toUpperCase()} </IonButton>
                         }

                   {/*      */}

                    {/*     <IonButton onClick={missionChecklist} shape="round" fill="solid" style={{ '--background': '#66cc33', '--color':'#ffffff'}} className="btn-accept-mission bolder">*/}
                    {/*            */}
                    {/*</IonButton>*/}
                </IonButtons>}
                 {
                     loggedInUser?.role === 'client' &&
                     <IonButtons>
                         <IonButton onClick={createMission} shape="round" fill="solid" style={{ '--background': '#66cc33', '--color':'#ffffff'}} className="btn-accept-mission bolder">   CREATE MISSION  </IonButton>

                     </IonButtons>
                 }
            </IonCol>
        </IonRow>


       {/*</IonGrid>*/}

    </IonCard>
          {/*{mission.submittedBy === loggedInUser.uid*/}
          {/*&&*/}


         <CompletedByGrid showSection={showCompletedSection} ctaObjectId={mission.uid} fromPage={'premiumMissions'} deliveryModule={deliveryModuleParam} objectType={'mission'} loggedInUser={loggedInUser}/>

      </>

);
};

export default MissionDeliveryComponent;

